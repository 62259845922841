import ResponsiveAppBar from "./ResponsiveAppBar";
import { UserDetails } from "./UserDetails/UserDetails";
import { AuthenticatedTemplate } from '@azure/msal-react';

export const UserPage = () => {
    return <>
        <ResponsiveAppBar />
        <AuthenticatedTemplate>
            <UserDetails />
        </AuthenticatedTemplate>
    </>;
}