import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './config/auth';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { PrivacyPolicy } from './components/PrivacyPolicy/PrivacyPolicy';
import { UserPage } from './components/UserPage';
import { configureAxios } from './config/axiosconfiguation';
import AppLink from './components/AppLink';

const router = createBrowserRouter([
  {
    path: "/",
    element:
      <App />
    ,
  },
  {
    path: "/app",
    element:
      <AppLink />
    ,
  },
  {
    path: "profile",
    element:
      <UserPage />
    ,
  },
  {
    path: "privacypolicy",
    element: <PrivacyPolicy />,
  },
]);

const msalInstance = new PublicClientApplication(msalConfig);
configureAxios(msalInstance);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <RouterProvider router={router} />
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
