import { PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import { loginRequest } from './auth';

export const configureAxios = (msalApp: PublicClientApplication) => {
    axios.defaults.baseURL = 'https://api.lifelongcards.com'; //'http://localhost';

    axios.interceptors.request.use(async function (config) {
        const accessToken = await _acquireAccessToken(msalApp);
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
    });
}

async function _acquireAccessToken(msalApp: PublicClientApplication) {
    const activeAccount = msalApp.getActiveAccount();
    const accounts = msalApp.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        /*
         * User is not signed in. Throw error or wait for user to login.
         * Do not attempt to log a user in outside of the context of MsalProvider
         */
    }

    const request = {
        ...loginRequest,
        account: activeAccount || accounts[0],
    };

    const authResult = await msalApp.acquireTokenSilent(request);

    return authResult.accessToken;
}