import './App.css';
import { HomePage } from './components/HomePage/HomePage';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { UserPage } from './components/UserPage';

function App() {
  return (<>
    <AuthenticatedTemplate>
      <UserPage />
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <HomePage />
    </UnauthenticatedTemplate>
  </>
  );
}

export default App;
