import { Configuration } from "@azure/msal-browser";

export const msalConfig: Configuration = {
    auth: {
        clientId: 'f7642fe7-0518-488b-90b3-871f8913782b',
        authority: 'https://lifelongcards.b2clogin.com/lifelongcards.onmicrosoft.com/B2C_1_signin',
        knownAuthorities: ['lifelongcards.b2clogin.com'],
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        // loggerOptions: {
        //     loggerCallback: (level: any, message: any, containsPii: any) => {
        //         if (containsPii) {
        //             return;
        //         }
        //         switch (level) {
        //             case LogLevel.Error:
        //                 console.error(message);
        //                 return;
        //             case LogLevel.Info:
        //                 console.info(message);
        //                 return;
        //             case LogLevel.Verbose:
        //                 console.debug(message);
        //                 return;
        //             case LogLevel.Warning:
        //                 console.warn(message);
        //                 return;
        //             default:
        //                 return;
        //         }
        //     }
        // }
    }
};

export const loginRequest = {
    scopes: [
        'openid',
        'profile',
        'email',
        'offline_access',
        'https://lifelongcards.onmicrosoft.com/api/read'
    ]
};
