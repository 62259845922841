import { Alert, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useState } from "react";
import { deleteProfileData } from "./DeleteDataButton.services";
import { useMsal } from "@azure/msal-react";

export const DeleteDataButton = () => {
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [snackbar, setSnackbar] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [severity, setSeverity] = useState<'success' | 'error'>('error');
    const { instance, accounts } = useMsal();

    const openDeleteDialog = () => {
        setOpen(true);
    };

    const closeDeleteDialog = () => {
        setOpen(false);
    };

    async function callDeleteProfileDataApi() {
        try {
            var tokenClaims = accounts[0].idTokenClaims;
            var userId = tokenClaims?.sub;
            await deleteProfileData(userId);
            setDeleted(true);
            setSeverity('success');
            setSnackbar(true);
        } catch (error) {
            setSeverity('error');
        }
    }

    const deleteDataHandler = async () => {
        setOpen(false);
        setLoader(true);
        await callDeleteProfileDataApi();
        setLoader(false);
    }

    const handleSnackbar = () => {
        if (deleted) {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
        setSnackbar(false);
    }

    return <>
        <Button variant="outlined" startIcon={<Delete />} onClick={openDeleteDialog}>
            Delete Data
        </Button>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loader}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog
            open={open}
            onClose={closeDeleteDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Do you want to delete all your data?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This action is irreversible, All your data will be deleted.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDeleteDialog}>No</Button>
                <Button onClick={deleteDataHandler} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={snackbar} autoHideDuration={4000} onClose={handleSnackbar}>
            <Alert
                onClose={handleSnackbar}
                severity={severity}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {deleted ? 'Data deleted successfully!' : 'Something went wrong, Please try again.'}
            </Alert>
        </Snackbar>
    </>;
}