import { SignInButton } from '../SignInButton';
import './HomePage.css';

export const HomePage = () => {
    return <div className='home'>
        <img alt="LifeLong Cards Logo" src="logo192.png" />
        <h1>LifeLong Cards</h1>
        <p>Your Card, Your Control</p>
        <div>
            <a
                href="https://play.google.com/store/apps/details?id=com.lifelongcards.app"
                target="_blank"
                rel="noreferrer"
            ><img
                    className='image-align'
                    alt="Get it on google play"
                    style={{ height: "60px" }}
                    src="google-play-badge.png"
                /></a>
            <a href="https://testflight.apple.com/join/edxyCJeP" target="_blank" rel="noreferrer"
            ><img
                    className='image-align'
                    alt="Get it on App Store"
                    style={{ height: "45px" }}
                    src="apple-store-badge.png"
                /></a>
        </div>
        <SignInButton />
    </div>;
}