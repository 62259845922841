import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { Box, List, ListItem, ListItemText } from '@mui/material';
import { DeleteProfileButton } from "../DeleteProfileButton/DeleteProfileButton";
import { DeleteDataButton } from "../DeleteDataButton/DeleteDataButton";

export const UserDetails = () => {
    const { accounts } = useMsal();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        var tokenClaims = accounts[0].idTokenClaims;
        setName(`${tokenClaims?.given_name} ${tokenClaims?.family_name}`);
        setEmail(`${tokenClaims?.emails?.[0]}`);
    }, [accounts]);

    return <>
        <List>
            <ListItem style={{ textAlign: 'center' }}>
                <ListItemText primary={`Name : ${name}`} />
            </ListItem>
            <ListItem style={{ textAlign: 'center' }}>
                <ListItemText primary={`Email : ${email}`} />
            </ListItem>
            <Box textAlign='center'>
                <DeleteDataButton />
                <br />
                <br />
                <DeleteProfileButton />
            </Box>
        </List>
    </>;
}