import * as React from 'react';

function AppLink() {

    React.useEffect(() => {
        if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
            window.location.href = "https://testflight.apple.com/join/edxyCJeP";
        } else {
            window.location.href =
                "https://play.google.com/store/apps/details?id=com.lifelongcards.app";
        }
    }, []);
    return (
        <div />
    );
}
export default AppLink;