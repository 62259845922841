import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import { Logout } from '@mui/icons-material';
import { useMsal } from "@azure/msal-react";

export const SignOutButton = () => {
    const { instance } = useMsal();
    const signOut = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    };
    return <MenuItem key={"Logout"} onClick={signOut}>
        <ListItemIcon>
            <Logout fontSize="small" />
        </ListItemIcon>
        <Typography textAlign="center">Logout</Typography>
    </MenuItem>;
}