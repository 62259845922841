import { Button } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/auth";

export const SignInButton = () => {
    const { instance } = useMsal();
    const signIn = () => {
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
    };
    return <Button
        key={"signin"}
        onClick={signIn}
        sx={{ my: 2, color: 'white' }}
    >
        Sign In
    </Button>;
}